
import './App.css';
import CardSection from './cardsection';
import TopHeader from './topheader';
import CarouselHouse from './carouselhouse';
function Home() {
  return (
    <div>

    <TopHeader />
    <CardSection />
    <CarouselHouse />
</div> 
  );
}

export default Home;
